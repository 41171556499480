.reset-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 904px;
  height: 100vh;
}
.reset-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e7e2e2;
  width: 100%;
  padding: 49px 0;
}
.reset-header h1 {
  font-size: 50px;
  font-style: normal;
  font-family: Segoe UI;
  font-weight: 400;
}
.email-reset-content {
  padding-top: 70px;
}
.code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2vh;
}
.code-container h1 {
  font-family: Segoe UI;
  font-weight: 350;
  font-size: 25px;
  line-height: 27px;
  letter-spacing: 0.05em;
  color: #434b62;
}
.reset-btn {
  font-family: Segoe UI;
  border: none;
  border-radius: 5px;
  background: #434b62;
  color: #ffffff;
  font-size: 28px;
  padding: 1vh 3.5vw;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.2em;
  margin-top: 2vh;
}
.reset-send-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}
.reset-content {
  display: flex;
  justify-content: center;
}
.code-inputs{
  margin-top: 17px;
}
.code-input {
  margin-right: 1vw;
  border: 1px solid #dcdcdc6b;
  padding-top: 1vh;
  padding-bottom: 1vh;
  text-align: center;
  width: 55px;
  height: 52px;
  border-radius: 8px;
  box-shadow: 0px 2px 2px 0px #00000040 inset;
  font-size: 28px;
}
.successfull {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.successfull-content {
  height: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.successfull-text h3,
.successfull-text p {
  font-family: Segoe UI;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.05em;
  margin: 2vh 0;
}
.successfull-row-2 {
  display: flex;
  justify-content: center;
}
.email-reset-input {
  width: 484px;
}
.code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
