.projects-image-div-box-icons{
    position: relative;
}

.projects-icons-div-box {
    position: absolute;
    top: 10px;
    right: 10px;
}

.trash-icon-edit {
    position: relative;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
}