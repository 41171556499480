.contact-layout-info {
  margin-top: 0;
  padding: 25px 0 0 26.5px;
}
.layout-info-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.contact-input-div {
  margin-top: 1.5vh;
}
.contact-input-div p {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  font-family: Segoe UI;
  color: #000000;
}
.contact-image {
  position: relative;
  width: 280px;
}
.contact-image img:not(:last-child){
  width: 100%;
}
.contact-input-div input {
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  width: 40vw;
  padding: 1vh;
  margin-top: 0.5vh;
}
.contact-input {
  position: relative;
}
