.main-div-for-edit-data {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.div-for-added_btn-and-photos-box {
    display: flex;
    gap: 15px;
}

.div-for-photos-box {
    height: max-content;
    display: grid;
    grid-template-columns: repeat(3, 200px);
    gap: 15px;
}

.selected-projects-img {
    width: 100%;
    height: 200px;
}

.new-project-inputs input {
    min-width: 190px;
}

@media screen and (max-width: 1550px) {
    .div-for-photos-box {
        grid-template-columns: repeat(2, 200px);
    }
}

@media screen and (max-width: 1200px) {
    .div-for-photos-box {
        grid-template-columns: repeat(2, 180px);
    }

    .selected-projects-img {
        height: 180px;
    }
}

@media screen and (max-width: 1110px) {
    .div-for-added_btn-and-photos-box {
        flex-direction: column;
    }
}

@media screen and (max-width: 990px) {
    .main-div-for-edit-data {
        flex-direction: column;
    }

    .div-for-added_btn-and-photos-box {
        flex-direction: row;
        justify-content: space-between;
    }

    .div-for-photos-box {
        grid-template-columns: repeat(3, 180px);
    }

    .edit-project-inputs-box {
        gap: 10px;
    }

    .new-project-inputs input {
        width: 90%;
    }
}

@media screen and (max-width: 760px) {
    .div-for-photos-box {
        grid-template-columns: repeat(2, 180px);
    }

    .edit-button-selected-projects {
        width: max-content;
        padding: 5px 10px;
    }
}