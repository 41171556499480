.layout-contnet-item {
  margin-top: 4vh;
  display: flex;
}
.item {
  position: relative;
  margin-right: 3vw;
  width: 18vw;
  height: 18vh;
}
.item iframe{
  width: 100%;
  height: 100%;
}
.item-image {
  width: 100%;
  height: 100%;
}
.trash-icon {
  position: absolute;
  right: 5px;
  top: 8px;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 3px;
  cursor: pointer;
}
