.layout {
  display: grid;
  grid-template-columns: 385px auto;
  grid-column-gap: 30px;
  padding-top: 33px;
}
.addProjectLayout {
  display: flex;
  flex-direction: column;
}
.layout-left-bar {
  padding: 0 3vw 0 97px;
  width: max-content;
}

.studio-layout-info,
.home-layout-info {
  padding: 0 3vw 0 10vw;
}
.menu-left-bar {
  padding: 0 1.1vw 0 5vw;
  width: max-content;
}
.layout-content {
  width: -webkit-fill-available;
  padding-right: 4vw;
  height: 100vh;
  overflow: auto;
  padding-bottom: 100px;
}
.layout-header {
  margin-bottom: 5vh;
}
.layout-header h1 {
  font-family: Source Serif Pro;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
}
.layout-content-info {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1vw;
  color: #0f1938;
}
.layout-content-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.47);
  width: 100%;
  margin-top: 80px;
}
.layout-content-info-name {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0.05em;
}
.layout-content-info-header {
  font-family: Segoe UI;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.05em;
}
.layout-content-item {
  margin-top: 4vh;
}
.layout-header-pages {
  display: flex;
}
.layout-header-pages p {
  font-weight: 300;
  font-family: Segoe UI;
  cursor: pointer;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #0f1938;
}
.layout-header-pages p:not(:first-child) {
  margin: 0vw 0 16px 2.6vw;
}
.active {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23px !important;
}
.projects-layout-info {
  padding-top: 29px;
}
.layout-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.7vh 0 0 1.9vw;
}

.file-input {
  width: 132px;
  height: 159px;
  cursor: pointer;
  border: 2px dashed #0f1938;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
}
.file-input p {
  font-weight: 200;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #0f1938;
}
.layout-items {
  display: flex;
  flex-wrap: wrap;
}
.layout-content-info {
}
