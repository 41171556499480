.App{
  height: 100vh;
  overflow: auto;
}
.add-btn-image {
  width: 22px;
  height: 22px;
}
.add-pdf{
  width: 32px !important;
  height: 32px !important;
}
::-webkit-scrollbar {
  width: 1em;
  max-height: 312px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #fcfcfc;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}
