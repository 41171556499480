.studio-team-item,
.studio-videos-item {
  width: min-content;
  position: relative;
  display: flex;
  flex-direction: column;
}
.team-item-img {
  width: 14vw;
  height: 15vh;
}
.studio-team-item textarea,
.studio-videos-item textarea {
  padding-left: 10px;
  resize: none;
  margin-top: 1vh;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  font-family: Segoe UI;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  overflow: hidden;
}
.team-name {
  height: 2.7vh;
}
.team-description {
  padding-bottom: 2vh;
  display: flex;
  flex-wrap: wrap;
}
.studio-team-sub-pages {
  display: flex;
}
.studio-team-sub-pages p:not(:first-child) {
  margin-left: 1vw;
}
.studio-team-sub-pages p {
  font-family: Segoe UI;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: #0f1938;
  margin-bottom: 1vh;
  cursor: pointer;
}
.team-image {
  position: relative;
  width: 300px;
  margin-top: 4vh;
}
.team-image img:not(:last-child) {
  width: 100%;
}
.team-description-input {
  padding-top: 15px;
  padding-left: 15px;
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  width: 585px;
  height: 15vh;
  resize: none;
  margin-top: 1vh;
  font-size: 15px;
  color: #bcbcbc;
  font-family: Source Serif Pro;
  margin-right: 3vw;
}
.items {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-row-gap: 40px;
  margin-top: 10vh;
}
.confirm-btn {
  border: none;
  background: #434b62;
  border-radius: 5px;
  font-family: Segoe UI;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #ffffff;
  cursor: pointer;
  padding: 8px 19px;
  cursor: pointer;
}
