.new-project-layout-info-content {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: max-content auto max-content;
}

.new-project-inputs {
  display: grid;
  grid-template-columns: auto auto;
}

.new-project-inputs input {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  width: 14.5vw;
  padding: 1vh;
  margin: 0.8vw;
  font-family: Source Serif Pro;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #bcbcbc;
}

.new-project-content {
  display: flex;
  flex-direction: column;
}

.new-project-layout-content {
  padding-left: 12.2vw;
}

.new-layout-content-header {
  margin-top: 0;
}

.new-project-layout-left-bar {
  padding-left: 5vw;
}

.description-input {
  padding-bottom: 21vh !important;
}

.add-project {
  background: #434b62;
  border-radius: 5px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  width: 5.5vw;
  padding: 0.5vw;
  font-family: Segoe UI;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  margin: auto;
}

.added-images {
  display: flex;
  flex-wrap: wrap;
}

.back-icon {
  cursor: pointer;
  width: 28px;
  height: 15px;
  position: absolute;
  left: -40px;
}

.add-selected-project {
  background: #434b62;
  border-radius: 5px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 8px 19px;
  cursor: pointer;
}

.projects .layout-info-content {
  display: unset !important;
  width: 100%;
}

.selected-items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.selected-project-item {
  display: flex;
  margin-top: 30px;
  margin-right: 75px;
  position: relative;
}

.selected-project-item .trash-icon {
  right: -15% !important;
}

.added-images img {
  width: 13vw;
  height: 7.8vw;
  margin-right: 18px;
}

.selected-project-item img:not(:last-child) {
  width: 200px;
  height: 150px;
  object-fit: cover
}

.selected-projects-items-other {
  display: flex;
  margin-top: 30px;
  margin-right: 75px;
}

.selected-project-item-img {
  width: 200px;
  height: 150px;
  object-fit: cover
}

.selected-project-item-icons-box {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selected-projects-icon {
  width: 27px;
  height: 27px;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 3px;
  cursor: pointer;
}