.job-info {
  display: grid;
  grid-template-columns: 24vw 24vw;
}
.job-info .confirm{
  margin-top: 33px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.job-info input {
  padding: 0.7vw;
  margin-top: 4vh;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  width: 23vw;
  margin-right: 23px;
  color: #bcbcbc;
}
.added-job-info-img{
    position: relative;
    width: 308px;
    height: 335px;
    margin-left: 50px;
    margin-right: 55px;
}
.added-job-info-img img:not(:last-child){
  width: 100%;
  height: 100%;
}
.job-info-img img{
    width: 12vw;
}
.job-items{
    margin-top: 3vh;
    position: relative;
    width: 13vw;
}
.job-item-img{
    width: 11vw;
    height: 24vh;
}
