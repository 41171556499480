.admin-logIn {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 904px;
  margin: auto;
}
.admin-container {
  width: 904px;
  height: 627px;
}
.admin-input {
  border: 1px solid #dcdcdc6b;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0px #00000040 inset;
  padding: 14px 33px 15px 23px;
  width: 100%;
  font-family: Segoe UI;
  font-size: 24px;
  letter-spacing: 0.15em;
}
.danger {
  border: 1px solid #ff000080;
  color: #ff000080;
}
.logIn-header {
  border-bottom: 1px solid #e7e2e2;
  width: 100%;
  padding: 34px 0 34px 125px;
}
.logIn-header h1 {
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  letter-spacing: 0.12em;
}
.logIn-content {
  padding-top: 40px;
  width: 653px;
  margin: 0 auto;
}
.email-input,
.password-input {
  position: relative;
}
.login-icons {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-size: cover;
}
.password-input {
  margin-top: 4vh;
}
.lock-icon {
  background-image: url("../../images/login-lock-icon.png");
}
.forget-password {
  color: #000000;
  margin-left: 25px;
  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.05em;
}
.logiIn-button {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}
.admin-logIn-btn {
  font-family: Segoe UI;
  border: none;
  border-radius: 5px;
  background: #434b62;
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  padding: 8.5px 34.5px;
  cursor: pointer;
}
.remember-input {
  height: 23px;
  width: 23px;
  border-radius: 5px;
  border: 1px solid #7474746b;
  color: #ffffff;
  margin-right: 10px;
}
.remember-checkbox {
  display: flex;
  margin-top: 5vh;
}
.remember-checkbox label {
  font-family: Segoe UI;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #000000;
}
.danger-text {
  color: red;
  font-size: 20px;
  margin: 2vh 0 2vh 1.5vw;
}
