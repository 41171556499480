.home {
  display: flex;
  justify-content: space-between;
}
.home-header h1 {
  font-family: Source Serif Pro;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 63px;
  letter-spacing: 0.12em;
}
.pages-menu dd {
  cursor: pointer;
}
.pages-menu dd,
.pages-menu dt {
  font-family: Segoe UI;
  padding: 0.7vh 0;
}
.pages-menu {
  padding-right: 55.5px;
  padding-bottom: 157px;
  width: 289px;
}
.home-pageheader {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: #0f1938;
}
.pages {
  border-right: 1px solid #00000078;
}
.home-content {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 100vh;
  position: relative;
}
.home-content-img {
  width: 50vw;
}
.admin-welcome {
  font-family: Segoe UI;
  font-size: 2.6vw;
  font-weight: 300;
  width: max-content;
  color: #000;
  position: absolute;
  top: 44.5%;
  transform: translateY(-50%);  
  left: -9%; 
}
.landScape{
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: #0f1938;
  width: 105%;
}
